
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.description {
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: $camplejeuneapplication-grey;
        z-index: -1;
    }
    h2 {
        margin: 0 auto;
        width: fit-content;
        font-size: 24px;
        font-weight: bold;
        @include lg {
            font-size: 30px;
            line-height: 45px;
        }
    }
    p {
        margin: 10px auto 0 auto;
        max-width: 1054px;
        text-align: left;
        font-size: 16px;
        @include lg {
            text-align: center;

            font-size: 18px;
            line-height: 32px;
        }
    }
}
